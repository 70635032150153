var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';
import { Button, Row, Col, Form } from '../../components/common/bootstrap';
import { TextInput, SelectInput } from '../../components/common/inputs';
import { partnerAPI } from '../../api/partnerAPI';
import CheckAdmin from '../../components/common/CheckAdmin';
import { maskTypeValues, editorTypes } from '../../helpers';
import { savePartnerSchema } from './validations';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Partner(_a) {
    var _this = this;
    var isNew = _a.isNew;
    var _b = useParams().id, id = _b === void 0 ? '' : _b;
    var _c = __read(useState(true), 2), skipGetPartner = _c[0], setSkipGetPartner = _c[1];
    var _d = __read(partnerAPI.useSavePartnerMutation(), 1), savePartner = _d[0];
    var partner = partnerAPI.useGetPartnerQuery(id, { skip: skipGetPartner });
    var partnerData = partner.data;
    var partnerName = _.get(partnerData, 'owner', '');
    var _e = useForm({
        resolver: yupResolver(savePartnerSchema)
    }), register = _e.register, reset = _e.reset, setValue = _e.setValue, handleSubmit = _e.handleSubmit, errors = _e.formState.errors;
    var generateKey = function () {
        var randomKey = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
            + Math.random().toString(36).slice(2);
        setValue('key', randomKey);
    };
    var handleSavePartner = function (_a) {
        var key = _a.key, owner = _a.owner, enabled = _a.enabled, isAdmin = _a.isAdmin, publicStationPhotosHash = _a.publicStationPhotosHash, maskType = _a.maskType, editorType = _a.editorType;
        return __awaiter(_this, void 0, void 0, function () {
            var authKeyId, stationHash;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!owner) return [3 /*break*/, 2];
                        authKeyId = id || uuidv4();
                        stationHash = publicStationPhotosHash || uuidv4();
                        return [4 /*yield*/, savePartner({
                                body: { key: key, owner: owner, enabled: enabled, isAdmin: isAdmin, publicStationPhotosHash: stationHash, maskType: maskType, editorType: editorType },
                                authKeyId: authKeyId
                            })];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    var renderForm = function () { return (_jsx(_Fragment, { children: _jsxs(Form, __assign({ onSubmit: handleSubmit(handleSavePartner) }, { children: [_jsx(Row, { children: _jsx(Col, __assign({ md: 4 }, { children: _jsxs(Form.Group, { children: [_jsx(TextInput, { register: register, name: 'owner', errors: errors, label: '\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435 \u043F\u0430\u0440\u0442\u043D\u0435\u0440\u0430' }, void 0), _jsxs("div", __assign({ className: 'mb-2' }, { children: [_jsx(TextInput, { register: register, name: 'key', errors: errors, label: '\u041A\u043B\u044E\u0447' }, void 0), _jsx(Button, __assign({ variant: 'info', onClick: generateKey }, { children: "\u0421\u0433\u0435\u043D\u0435\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }), void 0)] }), void 0), _jsx(SelectInput, { register: register, name: 'maskType', errors: errors, label: '\u0412\u0438\u0434 \u0442\u0440\u0430\u0444\u0430\u0440\u0435\u0442\u0430', values: maskTypeValues }, void 0), _jsx(SelectInput, { register: register, name: 'editorType', errors: errors, label: '\u0422\u0438\u043F \u0440\u0435\u0434\u0430\u043A\u0442\u043E\u0440\u0430', values: editorTypes }, void 0), _jsx(Form.Check, __assign({ type: 'checkbox', label: '\u0410\u0434\u043C\u0438\u043D', id: 'isAdmin' }, register('isAdmin')), void 0), _jsx(Form.Check, __assign({ type: 'checkbox', label: '\u0410\u043A\u0442\u0438\u0432\u0435\u043D', id: 'enabled' }, register('enabled')), void 0)] }, void 0) }), void 0) }, void 0), _jsx(Row, { children: _jsx(Col, __assign({ md: 4 }, { children: _jsx("div", __assign({ className: 'text-right' }, { children: _jsx(Button, __assign({ variant: 'primary', type: 'submit' }, { children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" }), void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }, void 0)); };
    useEffect(function () {
        if (!isNew) {
            setSkipGetPartner(!skipGetPartner);
        }
    }, []);
    useEffect(function () {
        if (partnerData) {
            var owner = partnerData.owner, enabled = partnerData.enabled, isAdmin = partnerData.isAdmin, key = partnerData.key, publicStationPhotosHash = partnerData.publicStationPhotosHash, maskType = partnerData.maskType, editorType = partnerData.editorType;
            reset({
                key: key,
                owner: owner,
                enabled: enabled,
                isAdmin: isAdmin,
                maskType: _.lowerFirst(maskType),
                editorType: _.lowerFirst(editorType),
                publicStationPhotosHash: publicStationPhotosHash
            });
        }
        if (isNew) {
            reset({
                key: '',
                owner: '',
                enabled: false,
                isAdmin: false,
                maskType: '',
                editorType: '',
                publicStationPhotosHash: ''
            });
        }
    }, [partnerData]);
    var labelText = isNew ? 'Новый партнер' : "\u041F\u0430\u0440\u0442\u043D\u0435\u0440: ".concat(partnerName);
    return (_jsxs(CheckAdmin, { children: [_jsx("h4", { children: labelText }, void 0), renderForm()] }, void 0));
}
export default Partner;
