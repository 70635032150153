////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import * as Yup from 'yup';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var savePartnerSchema = Yup.object().shape({
    owner: Yup.string().required('Введите наименование партнера'),
    key: Yup.string().required('Введите ключ'),
    maskType: Yup.string().required('Выберите вид трафарета'),
    editorType: Yup.string().required('Выберите тип редактора'),
    isAdmin: Yup.bool(),
    enabled: Yup.bool()
});
